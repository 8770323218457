import { navigate } from 'gatsby';
import { loginTwoFactorOnSubmit } from './onSubmit';

export const populateFields = ({ questions, defaultValues = {}, values }) => {
  const fields = [];
  questions.forEach((item) => {
    const { question, description, title, nextButtonLabel } = item;

    const questionData = {
      'Phone number': {
        type: 'string',
        validation: {
          required: true,
          pattern: /^04(\s?[0-9]{2}\s?)([0-9]{3}\s?[0-9]{3}|[0-9]{2}\s?[0-9]{2}\s?[0-9]{2})$/i,
        },
        placeholder: 'Enter your phone number',
        autoFocus: true,
        validationMessage: 'Please enter a valid phone number',
        defaultValue: defaultValues.phoneNumber,
        prevButtonLabel: "Don't have an account? Create one here",
        goPrev: () => navigate('/get-started'),
      },
      'Two Factor': {
        type: 'twoFactorModal',
        prevButtonLabel: 'Re-enter phone number',
        validation: {
          required: true,
          pattern: /^[0-9]{4}$/i,
        },
        validationMessage: 'Please enter the code you received via SMS',
        onSubmit: loginTwoFactorOnSubmit,
      },
    };

    const camalise = (str) => str.toLowerCase().replace(/[^a-zA-Z0-9]+(.)/g, (m, chr) => chr.toUpperCase());

    if (!questionData[question]) {
      return null;
    }

    const {
      type,
      options,
      placeholder,
      defaultValue,
      helper,
      validation,
      validationMessage,
      buttonText,
      onSubmit,
      autoFocus,
      submitOnClick,
      label,
      prevButtonLabel,
      goPrev,
    } = questionData[question];

    const name = camalise(question);

    fields.push({
      question,
      type,
      options,
      name,
      placeholder: placeholder || '',
      label,
      description,
      title,
      defaultValue: defaultValue || '',
      validation,
      validationMessage,
      buttonText,
      helper,
      onSubmit,
      autoFocus,
      submitOnClick,
      nextButtonLabel,
      prevButtonLabel,
      goPrev,
    });
  });
  return fields;
};
