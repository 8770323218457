import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { AppLayout, FormFlow } from 'components';
import { populateFields } from '../../utils/login/populateFields';
import './styles.scss';

const Login = ({ location }) => {
  const {
    loginSettings: { data: settingsData },
  } = useStaticQuery(loginSettingsQuery);
  const titleEnterNumber = settingsData?.login_title_verify_number;
  const descriptionEnterNumber = settingsData?.login_subtitle_verify_number;
  const titleEnterTwoFactor = settingsData?.login_title_enter_code;
  const descriptionEnterTwoFactor = settingsData?.login_subtitle_enter_code;

  const questions = [
    {
      question: 'Phone number',
      description: descriptionEnterNumber?.text,
      title: titleEnterNumber,
      nextButtonLabel: 'Verify Number',
    },
    {
      question: 'Two Factor',
      description: descriptionEnterTwoFactor?.text,
      title: titleEnterTwoFactor,
      nextButtonLabel: 'Login',
    },
  ];

  // Load fields
  const fields = populateFields({ questions });

  const seo = {
    title: 'Login to your application | YouBroker',
  };

  return (
    <AppLayout showTrustFactors hideSidebar location={location} seo={seo}>
      <section>
        <FormFlow fields={fields} hasTwoFactor hideProgressBar className="login" isLogin formHeaderAlignCenter />
      </section>
    </AppLayout>
  );
};
export default Login;

export const loginSettingsQuery = graphql`
  query loginSettingsQuery {
    loginSettings: prismicSettings {
      data {
        login_title_verify_number {
          html
          text
        }
        login_subtitle_verify_number {
          html
          text
        }
        login_title_enter_code {
          html
          text
        }
        login_subtitle_enter_code {
          html
          text
        }
      }
    }
  }
`;
