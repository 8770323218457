import { navigate } from 'gatsby';
import Cookies from 'js-cookie';
import { validateUser } from '../validateUser';
import { GET_USER_LOGIN_DATA } from '../../apollo/queries/queries_ff';
import { isDev, inThirtyMinutes } from '../misc';

export const loginTwoFactorOnSubmit = async ({ apolloClient, formData: { formData }, setError, dataLayer }) => {
  try {
    const validationResponse = await validateUser({ formData, skipCreate: true, isLeadFlow: false });

    const loginData = validationResponse?.data;

    Cookies.set('yb-token', `${loginData?.token}`, {
      expires: isDev ? 365 : inThirtyMinutes,
    });

    Cookies.set('yb-email', loginData?.email, {
      expires: isDev ? 365 : inThirtyMinutes,
    });

    const queryRes = await apolloClient.query({
      query: GET_USER_LOGIN_DATA,
      variables: {
        email: loginData?.email,
      },
    });

    const userData = queryRes?.data;

    Cookies.set('yb-app', userData.currentUser.applicationId, {
      expires: isDev ? 365 : inThirtyMinutes,
    });

    if (loginData) {
      dataLayer?.identify(loginData?.phoneNumber, {
        name: loginData?.name,
        email: loginData?.email,
        phone: loginData?.phoneNumber,
      });

      dataLayer?.track('Logged In');
    }

    const currentFlowStep = userData?.currentUser?.currentFlowStep;

    if (currentFlowStep === 1) {
      return navigate('/create-your-shortlist');
    }

    if (currentFlowStep === 2) {
      return navigate('/invite-other-applicants');
    }

    if (currentFlowStep === 3) {
      return navigate('/verify-your-details');
    }

    if (currentFlowStep === 4) {
      return navigate('/fact-find');
    }

    if (currentFlowStep === 5 || currentFlowStep === 6) {
      return navigate('/get-approved');
    }

    return navigate('/create-your-shortlist');
  } catch (error) {
    setError('twoFactor', {
      type: 'manual',
      message: error.response.data.fail || 'Something went wrong. Please contact support@youbroker.com',
    });
    console.error('@login', error);
    return error.message;
  }
};
